<template>
    <Header /><br /><br />
    <section class="hero-section py-5 position-relative overflow-hidden" style="background-color: #f8f7f2;">
        <div class="container">
            <div class="row">
                <div v-for="event in eventsbadges" :key="event.badge.id" class="col-md-3">
                    <div class="card mb-3">
                        <img :src="BACK_URL + event.badge.badge" class="card-img-top" alt="J'y serai" >
                        <div class="card-body">
                            <p class="text-dark">{{ event.badge.name }}</p>
                            <p class="card-text">
                                <a href="#" class="icon-link" style="color: black;"><i class="fas fa-eye"></i></a>
                                <span style="color: black;">{{ event.stats.views }}</span>
                                <span>&nbsp;</span>
                                <a href="#" class="icon-link" style="color: black;"><i class="fas fa-download"></i></a>
                                <span style="color: black;">{{ event.stats.downloads }}</span>
                            </p>
                            <a :href="'/badges/generate-badges/' + event.badge.id" class="btn btn-warning d-block text-center">Réalisez mon badge</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";
import { BACK_URL } from "@/event_badge.js";

export default {
    components: { Header },
    data() {
        return {
            eventsbadges: [],
            BACK_URL: BACK_URL,
        };
    },
    mounted() {
        this.fetchEventsBadges();
    },
    methods: {
        fetchEventsBadges() {
            axios.get('/event')
                .then(response => {
                    this.eventsbadges = response.data.badges;
                })
                .catch(error => {
                    console.error("There was an error fetching the events!", error);
                });
        }
    }
}
</script>

<style></style>
