<template>
    <section class="hero-section py-5 position-relative overflow-hidden" style="background-color: #f8f7f2;">
        <div class="container">
            <div class="hero-content-wrap">
                <h3 class="fw-bold display-5 text-center">J'y serai et toi ?</h3>
                <br />
            </div>
            <div class="row">
                <div v-for="badge in badges" :key="badge.badge.id" class="col-md-3">
                    <div class="card mb-3">
                        <img :src="BACK_URL + badge.badge.badge" class="card-img-top" alt="J'y serai">
                        <div class="card-body">
                            <h5 class="card-title">{{ badge.badge.name }}</h5>
                            <a :href="'/badges/generate-badges/' + badge.badge.id"
                                class="btn btn-warning d-block text-center">Réalisez mon badge</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from "@/axios/axios.js";
import { BACK_URL } from '@/event_badge.js';

export default {
    data() {
        return {
            badges: [],
            BACK_URL: BACK_URL,
        };
    },
    mounted() {
        this.fetchLatestBadges();
    },
    methods: {
        fetchLatestBadges() {
            axios.get(`/latest-badges`)
                .then(response => {
                    this.badges = response.data.badges;
                })
                .catch(error => {
                    console.error('Error fetching the latest badges:', error);
                });
        }
    }
}
</script>


<style></style>