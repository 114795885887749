<template>
  <main class="flex-shrink-0">
    <!-- Header-->
    <Header /> <br /><br />
    <header class="py-5 bg-blueish">
      <div class="container px-5 pb-5">
        <div class="row gx-5 align-items-center">
          <div class="col-xxl-5">
            <div class="text-center text-xxl-start">
              <div class="fs-3 fw-light text-dark">La solution idéale pour la gestion et la promotion de vos évènements
                et campagnes
              </div>
              <h1 class="display-3 fw-bolder mb-5">
                <span class="text-gradient d-inline" style="color: #000;">Mougni</span>
              </h1>
              <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                <a class="btn btn-warning btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="/login">Connexion</a>
                <a class="btn btn-outline-dark btn-lg px-8 py-3 fs-6 fw-bolder" href="/signup">Créer une
                  campagne</a>
              </div>
            </div>
          </div>
          <div class="col-xxl-7 image-container">
            <div class="d-flex justify-content-center mt-5 mt-xxl-0">
              <div
                class="profile bg-gradient-primary-to-secondary rounded d-flex justify-content-center align-items-center">
                <img class="profile-img smaller-img rounded" src="images/festichill.png" alt="J'y serai" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- <section class="container">
      <div class="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
        <div class="mx-auto w-full max-w-3xl text-center">
          <h2 class="text-3xl font-semibold md:text-5xl">Make Every Step <span
              class="bg-warning bg-cover bg-center bg-no-repeat px-4 text-white">User - Centric</span>
          </h2>
          <div class="mx-auto mb-8 mt-4 max-w-[528px] md:mb-12 lg:mb-16">
            <p class="text-[#636262]"> Personnalisez vos Avatars pour Promouvoir vos Événements</p>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 g-4">
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Support</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Organise</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Flexibility</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Speed</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Quality</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
          <div class="col mb-8">
            <div class="card border-warning shadow-custom">
              <div class="card-body">
                <h5 class="card-title mb-4 font-semibold" style="color: #EE5007;">Resources</h5>
                <p class="card-text">Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam, purus sit.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="hero-section py-5 position-relative overflow-hidden">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-xl-8 col-lg-10 mb-5">
            <div class="hero-content-wrap">
              <h1 class="fw-bold display-5 text-dark">
                Promouvoir vos évenements avec les Badge J'y serai
              </h1>

              <p>

                Transformez vos photos en oeuvre d'art événementielles et laissez le monde savoir où se trouve l'action
                !
              </p>
              <div class="action-btns text-center pt-4" data-aos="fade-up" data-aos-delay="100">
                <a href="/badges/all-badges" class="btn btn-warning me-lg-3 me-sm-3">
                  Voir les badges <i class="fa fa-arrow-right ms-3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <LatestBadge />

    <!--asked question-->
    <section class="mb-xl-9 mb-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
            <div class="text-center mb-7"><br />
              <h2>FAQ</h2>
              <p class="mb-0">
                Nous avons rassemblé les questions les plus fréquentes pour vous aider
                à mieux comprendre notre plateforme. Si vous avez d'autres questions, n'hésitez pas à nous contacter.

              </p>
            </div>
          </div>
        </div> <br />
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
            <div class="accordion" id="accordionExample">
              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne">
                    Qu'est-ce que Mougni?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">
                    Mougni est une plateforme en ligne qui vous permet de créer et de personnaliser des badges j'y serai
                    pour promouvoir vos événements. Avec Mougni, vous pouvez
                    ajouter des filtres, des images, du texte et bien plus encore à vos badges pour les rendre uniques
                    et attrayants.
                  </div>
                </div>
              </div>

              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                    aria-controls="collapseTwo">
                    Quels types d'événements puis-je promouvoir avec Mougni ?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">

                    Vous pouvez utiliser Mougni pour promouvoir une grande variété d'événements, tels que des concerts,
                    des festivals, des conférences, des rassemblements communautaires, des événements sportifs, des
                    campagnes caritatives, des anniversaires et bien plus encore. Quel que soit votre événement, Mougni
                    vous permet de créer des badges personnalisés qui attirent l'attention de votre public cible.

                  </div>
                </div>
              </div>
              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    Comment créer ou soumettre mon badge sur Mougni?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">

                    Inscrivez-vous ou connectez-vous à votre compte.
                    Accédez à l'onglet "Créer un badge".
                    Choisissez le type d'événement que vous souhaitez promouvoir.
                    Personnalisez votre badge avec les différentes options disponibles (couleurs, textes, images, etc.).
                    Enregistrez et téléchargez votre badge.
                  </div>
                </div>
              </div>
              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                    aria-controls="collapseFour">
                    Est-ce que l'utilisation de Mougni est gratuite ?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">
                    Mougni offre des options gratuites et payantes. Vous pouvez créer et personnaliser des badges
                    gratuitement. Cependant, certaines fonctionnalités avancées peuvent nécessiter un
                    abonnement ou un paiement unique.
                  </div>
                </div>
              </div>
              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                    aria-controls="collapseFive">
                    Comment puis-je promouvoir un événement avec un Badge J'y serai ?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">

                    Créez un Badge spécifique à votre événement.
                    Partagez le lien de création du badge avec votre communauté via les réseaux sociaux, emails, et
                    autres canaux de communication.
                    Encouragez les participants à utiliser le badge sur leurs profils pour montrer leur soutien et
                    créer une visibilité accrue pour l'événement.
                  </div>
                </div>
              </div>
              <div class="border mb-2 rounded-3 p-3">
                <h2 class="h5 mb-0">
                  <a href="#" class="text-reset d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                    aria-controls="collapseSix">
                    Que faire si j'ai des problèmes techniques sur Mougni ?
                    <span class="chevron-arrow"><i class="bi bi-chevron-down"></i></span>
                  </a>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div class="mt-3">
                    Si vous rencontrez des problèmes techniques, veuillez contacter notre service d'assistance via le
                    formulaire de contact sur le site ou envoyez un message WhatsApp à notre support en cliquant sur
                    <a href="https://wa.me/41438809?text=Bonjour%20**MOUGNI**"
                      class="text-decoration-none text-primary">ce lien</a>. Nous nous efforcerons de répondre à votre
                    demande dans les plus brefs délais.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--asked question-->
  </main> <br /><br /><br /><br /><br />
  <!-- Footer-->
  <footer class="py-4 mt-auto" style="background-color: #000; color: white;">
    <div class="container px-5">
      <div class="row align-items-center justify-content-between flex-column flex-sm-row">
        <div class="col-auto">

          <div class="small m-0">Mougni {{ new Date().getFullYear() }}</div>
        </div>
        <div class="col-auto">
          <div class="small m-0">Confidentialité</div>
        </div>
        <div class="col-auto">
          <div class="small m-0">Terms et conditions</div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";
import LatestBadge from "@/components/LatestBadge.vue";

export default {
  components: { Header, LatestBadge },

  methods: {
    async sendToDiscord(ipData) {
      const discordWebhookUrl = 'https://discord.com/api/webhooks/1266423976826114068/piqQlqDuCWeIR8vVYoB9MrRAd1Y-B0PrP7Wvm9BPtqalpJ8dfu4rAIXTYaPG839tCynr';

      const payload = {
        content: `**Nouvelle visite détectée :**
        - **IP :** ${ipData.ip}
        - **Succès :** ${ipData.success}
        - **Type :** ${ipData.type}
        - **Continent :** ${ipData.continent} (${ipData.continent_code})
        - **Pays :** ${ipData.country} (${ipData.country_code})
        - **Région :** ${ipData.region} (${ipData.region_code})
        - **Ville :** ${ipData.city}
        - **Latitude :** ${ipData.latitude}
        - **Longitude :** ${ipData.longitude}
        - **Code téléphonique :** +${ipData.calling_code}
        - **Capitale :** ${ipData.capital}
        - **Frontières :** ${ipData.borders}`
      };

      try {
        await axios.post(discordWebhookUrl, payload);
      } catch (error) {
        console.error('Échec de l\'envoi des données à Discord :', error);
      }
    },

    async fetchAndSendIPData() {
      try {
        // Récupérer les données IP
        const response = await axios.get('https://ipwho.is/');
        const ipData = response.data;
        
        // Envoyer les données IP à Discord
        await this.sendToDiscord(ipData);
      } catch (error) {
        console.error('Échec de la récupération des données IP :', error);
      }
    }
  },

  created() {
    // Récupérer et envoyer les données IP lors de la création du composant
    this.fetchAndSendIPData();
  }
};
</script>




<style>
.smaller-img {
  width: 80%;
  /* ou toute autre valeur de largeur souhaitée */
  height: auto;
  /* pour maintenir les proportions de l'image */
}


.image-container {
  margin-left: -1px;
  /* Décalage à gauche de 20px (ajustez selon vos besoins) */
}

.profile {
  border-radius: 50px;
  /* Ajustez la valeur selon vos préférences */
}

.profile-img {
  border-radius: 50px;
  /* Ajustez la valeur selon vos préférences */
}



@media only screen and (max-width: 768px) {
  .profile-img {
    width: 100%;
    /* Set image width to 100% of its container on small screens */
    height: auto;
    /* Maintain aspect ratio */
  }
}
</style>