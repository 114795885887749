<script>
import axios from "@/axios/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Header from "@/components/Header.vue";

export default {
    components: { Header },
    data() {
        return {
            email: '',
            loading: false
        };
    },
    methods: {
        async resetPassword() {
            try {
                this.loading = true;
                // Appel de l'API pour réinitialiser le mot de passe
                const response = await axios.post('/reset-password', { email: this.email });
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Un e-mail a été envoyé à votre adresse e-mail.',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: response.data.message,
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<template>
    <main class="main">
        <div class="container-fluid background-image">
            <div class="row justify-content-center align-items-center min-vh-100 py-5">
                <div class="col-md-10 col-lg-7 col-xl-6">
                    <div class="text-center mb-3">
                        <i class="bi bi-lock-fill" style="font-size: 3rem; color: #ffc107;"></i>
                    </div>
                    <div class="alert alert-secondary" role="alert">
                        <h6 class="alert-heading text-center">Entrez votre e-mail pour la réinitialisation</h6>
                    </div>
                    <div class="shadow p-3 mb-5 bg-body rounded">
                        <form @submit.prevent="resetPassword">
                            <div class="mb-3 text-start">
                                <label class="form-label" for="email">E-mail</label>
                                <div class="form-icon-container">
                                    <input 
                                        class="form-control form-icon-input" 
                                        v-model="email" 
                                        type="email"
                                        placeholder="mougniboss@gmail.com" 
                                        required
                                    />
                                </div>
                            </div>
                            <button 
                                class="btn btn-secondary w-100 mb-3" 
                                style="background-color: #ffc107;" 
                                type="submit" 
                                :disabled="loading"
                            >
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>Réinitialiser</span>
                            </button>
                        </form>
                        <div class="text-center">
                            <a class="fs--1 fw-bold text-black" href="/login">Se connecter</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
.background-image {
  background-image: url('/public/images/background.png'); /* Remplacez par le chemin de votre image */
  background-size: cover;
  background-position: center;
}
</style>